import * as Survey from 'survey-core'

class AddPropertyToSurvey {
  constructor(
    location,
    name,
    type,
    category,
    visibleIndex,
    choices,
    defaultValue
  ) {
    this.location = location
    this.name = name
    this.type = type
    this.category = category
    this.visibleIndex = visibleIndex
    this.choices = choices
    this.defaultValue = defaultValue
  }

  add() {
    Survey.Serializer.addProperty(this.location, {
      name: this.name,
      type: this.type,
      category: this.category,
      visibleIndex: this.visibleIndex,
      choices: this.choices,
      default: this.defaultValue,
    })
  }
}

class addDependsOnPropertyToSurvey extends AddPropertyToSurvey {
  constructor(
    location,
    name,
    type,
    category,
    visibleIndex,
    choices,
    defaultValue,
    dependsOn,
    dependsOnKey,
    dependsOnValue
  ) {
    super(location, name, type, category, visibleIndex, choices, defaultValue)

    this.dependsOn = dependsOn
    this.dependsOnKey = dependsOnKey
    this.dependsOnValue = dependsOnValue
  }

  add() {
    Survey.Serializer.addProperty(this.location, {
      name: this.name,
      type: this.type,
      category: this.category,
      visibleIndex: this.visibleIndex,
      choices: this.choices,
      default: this.defaultValue,
      dependsOn: [this.dependsOn],
      // Notice ==> i used arrow fn to make 'this' point out at the class not 'add' fn
      visibleIf: (obj) => {
        return obj[this.dependsOnKey] == this.dependsOnValue
      },
    })
  }
}

export const surveyValidFor = new AddPropertyToSurvey(
  'survey',
  'validFor',
  'boolean',
  'general',
  1,
  [
    { value: 'multipleTimes', text: 'multiple times survey' },

    { value: 'oneTime', text: 'one-time survey' },

    { value: 'oneTimePerXDays', text: 'one-time in X days survey' },
  ],
  'multipleTimes'
)

export const validAfterXDays = new addDependsOnPropertyToSurvey(
  'survey',
  'validAfterXDays',
  'number',
  'general',
  2,
  null,
  null,
  'validFor',
  'validFor',
  'oneTimePerXDays'
)

export const rightToLeftAtQuestion = new AddPropertyToSurvey(
  'question',
  'RTL',
  'boolean',
  'general',
  1
)

export const rightToLeftAtPage = new AddPropertyToSurvey(
  'page',
  'RTL',
  'boolean',
  'general',
  1
)
