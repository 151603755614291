import React, { useEffect, useState } from 'react'
import mvlogo from '../img/mv-main.png'
import lighthouselogo from '../img/lighthouse-logo.png'
import { Figure } from 'react-bootstrap'

const OuterUserHeader = (props) => {
  const lighHouseSurveys = [
    "7d3a356064c84b41bfe7994439212b5d",
    "32266e13670c4eea99c4cd2a4877d17b"
  ];

  const surveyID = props.surveyID;
  const [headerLogo, setHeaderLogo] = useState(false);

  useEffect(() => {
    if (lighHouseSurveys.includes(surveyID)) {
      setHeaderLogo(<Figure.Image width={400} style={{marginTop: 30, marginBottom: 30}} alt='MV Logo' src={lighthouselogo} />);
    } else {
      setHeaderLogo(<Figure.Image width={300} alt='MV Logo' src={mvlogo} />);
    }
  }, [surveyID]);

  return (
    <Figure className='d-flex flex-column justify-content-center align-items-center pt-5'>
      {headerLogo}
    </Figure>
  )
}

export default OuterUserHeader
