import React from 'react'
import ReactHtmlParser from 'html-react-parser';

const OnCompleteSurvey = ({ completeHTML }) => {
  const parser = new DOMParser();

  return (
    <div className='complete-html justify-content-center align-items-center text-center fst-italic p-5 mt-5 bg-grey text-white' >
      { ReactHtmlParser(ReactHtmlParser(completeHTML)) }
    </div>
  )
}

export default OnCompleteSurvey
