import React, { useContext, useEffect, useState } from 'react'
import * as Survey from 'survey-core'
import {DeviceUUID} from 'device-uuid'
import * as SurveyReact from 'survey-react-ui'
import 'survey-core/defaultV2.css'
import * as SurveyCreatorReact from 'survey-creator-react'
import { IntranetContext } from '../context'
import moment from 'moment'
import Navbar from '../components/Navbar'
import SkeletonLoader from '../components/Skeleton/SkeletonLoader'
import OuterUserHeader from '../components/singleSurveyPage/OuterUserHeader'
import UserHasAnsweredSurvey from '../components/singleSurveyPage/UserHasAnsweredSurvey'
import OnCompleteSurvey from '../components/singleSurveyPage/OnCompleteSurvey'
import {
  rightToLeftAtPage,
  rightToLeftAtQuestion,
} from '../utils/addPropertyToSurveyCreator'
import {
  registerBooleanText,
  registerNumberText,
} from '../utils/registerPropertyToCreator'
import {
  checkIfUserAnswered,
  checkIfUserShouldAnswerSurvey,
} from '../utils/checkIfUserShouldAnswerSurvey'

Survey.StylesManager.applyTheme('defaultV2')

// add right to left property to question
rightToLeftAtQuestion.add()
// add right to left property to page
rightToLeftAtPage.add()

// // register all boolean with short text properties
registerBooleanText.register()
registerNumberText.register()

export default function SingleSurveyPage(props) {
  const { fetchOneSurvey, addNewSurveyResult, user_id, addSurveyData } =
    useContext(IntranetContext)
  const surveyId = props.match.params.id

  const [PageNum, setPageNum] = useState(true);
  const storageName = "saved_survey_" + surveyId
  let du = new DeviceUUID().parse()
    let dua = [
        du.language,
        du.platform,
        du.os,
        du.cpuCores,
        du.isAuthoritative,
        du.silkAccelerated,
        du.isKindleFire,
        du.isDesktop,
        du.isMobile,
        du.isTablet,
        du.isWindows,
        du.isLinux,
        du.isLinux64,
        du.isMac,
        du.isiPad,
        du.isiPhone,
        du.isiPod,
        du.isSmartTV,
        du.pixelDepth,
        du.isTouchScreen
    ];
  const anonymous_id = du.hashMD5(dua.join(':'))
  function saveSurveyData(survey) {
    if (surveyId === "b18c50d51ec9412798806ccc19da260b") {
      var data = survey.data;
      data.pageNo = survey.currentPageNo;
      addSurveyData(anonymous_id, surveyId, data, survey.currentPageNo);
      window.localStorage.setItem(storageName, JSON.stringify(data));
    }
  }

  let { pathname, search } = props.location

  // get external attributes as key-value object to send them with result
  let extObj = {}
  const attributes = search.replace(`?`, '')

  if (attributes) {
    const extAttributes = attributes?.split('&')

    extAttributes.map((ext) => {
      const keyValue = ext.split('=')
      extObj[keyValue[0]] = keyValue[1]
    })
  }

  const [surveysAnswered, setSurveysAnswered] = useState(
    localStorage.getItem('surveysAnswered')
      ? JSON.parse(localStorage.getItem('surveysAnswered'))
      : null
  )
  const [model, setModel] = useState(null)
  const [isSurveyAvailable, setIsSurveyAvailable] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [completeHTML, setCompleteHTML] = useState('')
  const [validForType, setValidForType] = useState('')
  const [validAfterXDays, setValidAfterXDays] = useState(null)
  const [showSurvey, setShowSurvey] = useState(false)
  const [isSurveyLoading, setIsSurveyLoading] = useState(true)

  function onValueChanged(sender, options) {
    if (
      options.name === 'question1' &&
      options.question.title === 'Select language - اختر لغة'
    ) {
      setTimeout(() => {
        if (document.querySelector('.sd-navigation__next-btn')) {
          document.querySelector('.sd-navigation__next-btn').value =
            'Next - التالي'
        }
      }, 10)
    }
  }

  function onAfterRenderQuestion(survey, options) {
    if (options.question.RTL) {
      options.htmlElement.style.direction = 'rtl'
    }
  }

  // change css on every page

  function onAfterRenderPage(survey, options) {
    // check if first page is multi language
    let isSelectLanguagePage = options?.htmlElement?.innerText?.includes(
      'Select language - اختر لغة'
    )
    if (isSelectLanguagePage) {
      setTimeout(() => {
        if (document.querySelector('.sd-navigation__next-btn')) {
          document.querySelector('.sd-navigation__next-btn').value =
            'Next - التالي'
        }
      }, 10)
    }

    // changing css Right to Left
    let isPageRTL = options.page.RTL

    if (isPageRTL) {
      options.htmlElement.style.direction = 'rtl'

      //fixing arabic header css
      $('.sd-question__header').css('left', '-100%')

      // changing numbers' position to right
      let numberOfSpans = document.querySelectorAll('.sd-element__num')
      if (numberOfSpans.length > 0) {
        numberOfSpans.forEach((span) => {
          span.style.float = 'right'
          span.style.marginLeft = 0
        })
      }

      // next btn
      if (document.querySelector('.sd-navigation__next-btn')) {
        document.querySelector('.sd-navigation__next-btn').value = 'التالي'
      }
      // prev btn

      if (document.querySelector('.sd-navigation__prev-btn')) {
        document.querySelector('.sd-navigation__prev-btn').value = 'السابق'
      }
      // complete btn

      if (document.querySelector('.sd-navigation__complete-btn')) {
        document.querySelector('.sd-navigation__complete-btn').value =
          'ارسال البيانات'
      }

      document.querySelector('.sd-body__navigation').style.direction = 'rtl'
    } else {
      options.htmlElement.style.direction = 'ltr'
      if (document.querySelector('.sd-navigation__next-btn')) {
        document.querySelector('.sd-navigation__next-btn').value = 'Next'
      }
      if (document.querySelector('.sd-navigation__prev-btn')) {
        document.querySelector('.sd-navigation__prev-btn').value = 'Previous'
      }

      if (document.querySelector('.sd-navigation__complete-btn')) {
        document.querySelector('.sd-navigation__complete-btn').value =
          'Complete'
      }

      document.querySelector('.sd-body__navigation').style.direction = 'ltr'
    }

    if (PageNum) {
      var prevData = window.localStorage.getItem(storageName) || null;
      if(prevData) {
          var data = JSON.parse(prevData);
          model.data = data;
          if(data.pageNo) {
              model.currentPageNo = data.pageNo;
              setPageNum(false);
          }
      }
    }

    // Make the survey read html text as html elements
    $('.sd-html').html($('.sd-html').text())
  }

  function onComplete(sender, options, surveyId) {
    // adding timestamp, attributes obj to result

    const answerDateAfterXDays = moment().add(validAfterXDays, 'days')

    let result = {
      ...sender?.valuesHash,
      // answeredDate: currentGMTPlus2Hours,
    }

    // spread extObj key-value pairs inside result
    for (const key in extObj) {
      result[key] = extObj[key]
    }

    addNewSurveyResult(JSON.stringify(result), surveyId)

    const newLocalStorageSurveyObj = {
      surveyId,
      answerDateAfterXDays,
      validForType,
      validAfterXDays,
    }
    // check if user has completed certain survey
    if (surveysAnswered === null || surveysAnswered.length === 0) {
      localStorage.setItem(
        'surveysAnswered',
        JSON.stringify([newLocalStorageSurveyObj])
      )
    } else {
      localStorage.setItem(
        'surveysAnswered',
        JSON.stringify([...surveysAnswered, newLocalStorageSurveyObj])
      )
    }

    setIsCompleted(true);

    // changing survey completing message
    // let isArabic = sender?.valuesHash?.question1 === 'عربي' ? true : false
    // if (isArabic) {
    //   setCompleteHTML('شكرا لمشاركتنا رأيك اللي هيساعدنا على تحسين خدماتنا')
    // } else {
    //   setCompleteHTML('  Thank you for completing the survey! ')
    // }
  }

  useEffect(() => {
    fetchOneSurvey(surveyId)
      .then((res) => {
        if (!res.data) {
          setIsSurveyAvailable(false)
          setIsSurveyLoading(false)

          return
        }

        setIsSurveyAvailable(true)

        const surveyJson = JSON.parse(res.data[0].survey_json)

        setCompleteHTML(surveyJson.completedHtml);

        const { validFor, validAfterXDays } = surveyJson

        setValidForType(validFor)
        setValidAfterXDays(validAfterXDays)
        checkIfUserShouldAnswerSurvey(
          validFor,
          surveysAnswered,
          setShowSurvey,
          surveyId
        )

        setModel(new Survey.Model(surveyJson))
        setIsSurveyLoading(false)
      })

      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      {/* Header */}
      {/* check if user inside or outside mv  */}
      {user_id ? <Navbar /> : <OuterUserHeader surveyID={surveyId}/>}

      {isSurveyLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          {isSurveyAvailable ? (
            <>
              {/* body */}

              {!showSurvey ? (
                //  if user has already completed the survey and it is one-time survey
                <UserHasAnsweredSurvey />
              ) : (
                // survey model
                <>
                  {model && !isCompleted ? (
                    <SurveyReact.Survey
                      model={model}
                      showCompletedPage={false}
                      sendResultOnPageNext={true}
                      onComplete={(sender, options) => {
                          onComplete(sender, options, surveyId);
                          saveSurveyData(sender);
                        }
                      }
                      onValueChanged={onValueChanged}
                      onAfterRenderPage={onAfterRenderPage}
                      onAfterRenderQuestion={onAfterRenderQuestion}
                      onPartialSend={sender => saveSurveyData(sender)}
                    />
                  ) : (
                    <OnCompleteSurvey completeHTML={completeHTML} />
                  )}
                </>
              )}
            </>
          ) : (
            <div className='d-flex justify-content-center align-items-center pt-5 mt-5'>
              <h1>Survey not existed!!!</h1>
            </div>
          )}
        </>
      )}
    </>
  )
}
