import React from 'react'
import OuterUserHeader from './OuterUserHeader'

const UserHasAnsweredSurvey = () => {
  return (
    <>
      <div className='text-center pt-5 mt-5'>
        <h3 className='text-center p-5'>
          You have completed this survey before - لقد استكملت هذا الاستبيان من
          قبل
        </h3>
      </div>
    </>
  )
}

export default UserHasAnsweredSurvey
