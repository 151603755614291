import moment from 'moment'

export const checkIfUserShouldAnswerSurvey = (
  validationType,

  surveysAnswered,
  setShowSurvey,
  surveyId
) => {
  // if survey is multiple times
  if (validationType === 'multipleTimes' || !validationType) {
    setShowSurvey(true)
  } else if (surveysAnswered) {
    const userSurveyAnswers = surveysAnswered.filter(
      (survey) => survey.surveyId === surveyId
    )

    // user has already answered this survey before
    if (userSurveyAnswers.length > 0) {
      // if survey is one_time
      if (validationType === 'oneTime') {
        setShowSurvey(false)
      }

      // if survey is one_time in a month

      if (validationType === 'oneTimePerXDays') {
        const getLastUserAnswerIndex = userSurveyAnswers.length - 1
        const lastUserAnswer = userSurveyAnswers[getLastUserAnswerIndex]

        const { answerDateAfterXDays } = lastUserAnswer

        // check if user can answer again after 30 days
        const daysDiff = moment(answerDateAfterXDays).diff(moment(), 'days')

        const isXDaysPassed = daysDiff <= 0

        if (isXDaysPassed) {
          setShowSurvey(true)
        } else {
          setShowSurvey(false)
        }
      }
    }
    // user has not answered this survey before
    else {
      setShowSurvey(true)
    }
  } // user has not answered this survey before
  else {
    setShowSurvey(true)
  }
}
