import * as SurveyCreatorReact from 'survey-creator-react'

// Returns a standard survey/page/question JSON configuration for the property editor

class registerProperty {
  constructor(propType, type) {
    this.propType = propType
    this.type = type
  }

  register() {
    return SurveyCreatorReact.PropertyGridEditorCollection.register({
      fit: function (prop) {
        return prop.type === this.propType
      },
      getJSON: function (obj, prop, options) {
        return { type: this.type }
      },
    })
  }
}

export const registerBooleanText = new registerProperty('shorttext', 'boolean')
export const registerNumberText = new registerProperty('shorttext', 'number')
